export const GLENS_GR = {
  h1: {
    en: 'GKLEN Poiotikes Diamones',
    gr: 'ΓΚΛΕΝ Ποιοτικές Διαμονές',
  },
  h2: {
    en: 'Limited Partnership',
    gr: 'Ετερόρρυθμη Εταιρεία',
  },
  activites: {
    1: {
      en: 'Construction Works Or Construction Of Buildings Or Other Types Of Construction As An Investment Good Of Art 33 Paragraph 4 Of The Vat Code For The Exercise Of A Professional Activity With The Right To Deduct The Vat Of Their Input',
      gr: 'Εργασίες Διαμόρφωσης ή Ανέγερσης Κτίσματος ή Άλλου Είδους Κατασκευών ως Αγαθού Επένδυσης του Αρθρ. 33 Παρ. 4 του Κώδικα ΦΠΑ για Ίδια Άσκηση Επαγγελματικής Δραστηριότητας με Δικαίωμα Έκπτωσης του ΦΠΑ των Εισροών τους',
    },
  },
  administrator: {
    en: 'Panta Aikaterini',
    gr: 'Παντα Αικατερινη',
  },
  vat: {
    en: 'EL 802435459',
    gr: '802435459',
  },
  gemi: {
    en: '176304849000',
    gr: '176304849000',
  },
  address: {
    link: {
      en: 'https://maps.app.goo.gl/UpyEV45e3u6VTcHu8',
      gr: 'https://maps.app.goo.gl/UpyEV45e3u6VTcHu8',
    },
    part1: {
      en: 'Paralia Kanaliou 0',
      gr: 'Παραλία Καναλίου 0',
    },
    part2: {
      en: '48100, Preveza, Greece',
      gr: '48100, Πρέβεζα',
    }
  },
  website: {
    en: 'glens.gr',
    gr: 'glens.gr',
  },
  email: {
    en: 'info@glens.gr',
    gr: 'info@glens.gr',
  },
  phone: {
    en: '(+30) 6989459502',
    gr: '6989459502',
  },
}
